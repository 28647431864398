// A mock function to mimic making an async request for data
import axios from "axios";
export function createService(service) {
  return new Promise(async (resolve) => {
    const response =await axios.post(process.env.REACT_APP_BASE_API+"/service/add-service", service);
    const data = response.data;

    resolve({ data });
  });
}

export function fetchServiceByAdmin() {
  return new Promise(async (resolve) => {
    const response = await axios.get(
      process.env.REACT_APP_BASE_API+"/service/get-service-admin"
    );
    const data = response.data;
    resolve({ data });
  });
}

export function fetchServiceById(serviceId) {
  return new Promise(async (resolve) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API}service/get-service-by-id/${serviceId}`
    );

    const data = response.data;
    resolve({ data });
  });
}

export function updateService(serviceId, newService) {
  return new Promise(async (resolve) => {
   const response =  await axios.put(process.env.REACT_APP_BASE_API+"/service/update-service", {
      serviceId: serviceId,
      serviceName: newService.serviceName,
      content: newService.content,
      vedioLink: newService.vedioLink,
    });
    const data = response.data;

    resolve({ data });
  });
}


export function deleteServiceById(serviceId) {
  return new Promise(async (resolve) => {
    const response = await axios.patch(
      `${process.env.REACT_APP_BASE_API}/service/delete-service/${serviceId}`
    );
    const data = response.data;
    resolve({ data });
  });
}
export function toggleServiceStatus(serviceId) {
  return new Promise(async (resolve) => {
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/service/toggle-service-status/${serviceId}`
  );
    const data = response.data;
    resolve({ data });
  });
}