import axios from "axios";

// A mock function to mimic making an async request for data

  
export function updateUser({UserData,userId}) {
  return new Promise(async (resolve) => {
    const response = await axios.post(
      process.env.REACT_APP_BASE_API+"/users/update-user",
      {
      fullName: UserData.fullName,
      email: UserData.email,
      // mobileNo: req.body.userData.mobileNo,
      // city: req.body.userData.city,
      state: UserData.state,
      pincode:UserData.pincode
       ,
        userId:userId
      }
    );
    const data = response.data;
    console.log(data,"AKAKAKAK")
    resolve({ data });
  });
}

export function loginAdmin(userData) {
  
  return new Promise(async (resolve) => {
    const response = await axios.post(process.env.REACT_APP_BASE_API+"/users/admin-login", {
      userData: userData,
    }); 
    if(response?.data?.status === 1){
      console.log(response.data.data.token)
      sessionStorage.setItem('adminToken', response.data.data.token);
           
    }
    const data = response.data;

    resolve({ data });
  });
}
export function authMiddleware() {
  const token = sessionStorage.getItem("adminToken");
  return new Promise(async (resolve) => {
    const response = await axios.post(process.env.REACT_APP_BASE_API+"/users/auth-middleware", {
      token: token,
    }); 
    const data = response.data;

    resolve({ data });
  });
  
}

export function verifyOtpEmail({email,otp}) {
  return new Promise(async (resolve) => {
    const response = await axios.post(
      process.env.REACT_APP_BASE_API+"/users/verify-otp-email",
      {
        email: email,
        otp : otp
      }
    );
    const data = response.data;

    resolve({ data });
  });
}

//get all users
export function getAllUsers(queryString) {
  return new Promise(async (resolve) => {
    
    const response = await axios.get(
      process.env.REACT_APP_BASE_API+`/users/get-all-users?${queryString}`
    );
    const data =  response.data;
       resolve({ data });
  });
}



