import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Box,
  Text,
  Input,
  Select,
  Textarea,
  useDisclosure,
  ModalHeader,
  Image,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import ProductSpecification from "../AdminComponent/ProductSpecification";
import CenteredSpinner from "../../component/constant/CenteredSpinner ";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { selectAdmincategoryData } from "../../features/Category/CategorySlice";
import { fetchSubCategoryNameByCategoryIdAsync, selectSubcategoryByCategoryName } from "../../features/Subcategory/SubcategorySlice";
import { AddIcon, CloseIcon, DeleteIcon } from "@chakra-ui/icons";
import { updateProductAsync } from "../../features/Product/ProductSlice";
import axios from "axios";
import Swal from "sweetalert2";
import { editIcon } from "../../component/constant/Icons";
import { useNavigate } from "react-router-dom";

const style = {
  form_mainbox: {
    borderRadius: "28px",
    background: "#f3ffe1",
    padding: "24px",
    margin: "auto",
    marginTop: "20px",
    maxWidth: "90%", 
  },
  formheading: {
    color: "#404040",
    fontFamily: "Inter",
    fontSize: "40px",
    fontWeight: "700",
    letterSpacing: "1.2px",
    marginTop: "30px",
    marginLeft: "50px",
  },
  formInput: {
    width: "100%",
    height: "50px",
    borderRadius: "14px",
    border: "1px solid #A0A0A0",
    backgroundColor: "#FFF",
    paddingLeft: "10px",
    marginBottom: "12px",
  },
  formText: {
    color: "#404040",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "500",
    marginBottom: "6px",
  },
  formButton: {
    borderRadius: "14px",
    background: "#52771A",
    color: "#f3ffe1",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "500",
    padding: "12px 16px",
  },
  btnbox: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
};

function AdminEditModal({
  id,
  name,
  image,
  price,
  pricePerUnit,
  categoryName,
  subcategoryId,
  productDescription,
  productSpicility,
  subcategoryProduct
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const CategorySelector = useSelector(selectAdmincategoryData);
  // const SubCategorySelector = useSelector(selectSubcategoryByCategoryName);
  const [Images, setImages] = useState([...image]);     
  const [isLoading, setIsLoading] = useState(false); // Consolidated loading state
  const containerRef = useRef(null);
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFilesDuplicate, setSelectedFilesDuplicate] = useState([]);
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const [categoryId, setCategoryId] = useState(null);
  const [specifications, setSpecifications] = useState(productSpicility);

  const handleFileSelection = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFilesDuplicate(event.target.files);
    const fileNames = files.map((file) => file.name);
    setSelectedFiles(fileNames);
  };

  useEffect(() => {
    setValue("productName", name);
    setValue("productRate", price);
    setValue("pricePerUnit", pricePerUnit);
    setValue("productDescription", productDescription);
  }, [name, price, pricePerUnit, productDescription, setValue]);

  useEffect(() => {
    if (categoryId) {
      dispatch(fetchSubCategoryNameByCategoryIdAsync(categoryId));
    }
  }, [categoryId, dispatch]);

  const handleDeleteImage = (index) => {
    const updatedImages = [...Images];
    updatedImages.splice(index, 1);
    setImages(updatedImages); 
  };

  const handleSpecificationChange = (newSpecifications) => {
    setSpecifications(newSpecifications);
  };

  const handleIconButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      fileInputRef.current = document.getElementById("productImage");
      fileInputRef.current.click();
    }
  };

  const onSubmit = (data) => handleFormSubmit(data);

  const handleFormSubmit = async (data) => {
    setIsLoading(true);
    if (!data.categoryName) {
      data.categoryName = categoryName;
    }
    if (!data.subcategoryId) {
      data.subcategoryId = subcategoryId;
    }
    if (!categoryId) {
      data.categoryId = categoryId;
    }
    
    data.productImage = selectedFilesDuplicate;  
    const imageUrls = [];
    
    for (let i = 0; i < data.productImage.length; i++) {
      const formData = new FormData();
      formData.append("file", data.productImage[i]);
      formData.append("upload_preset", "mun8qyj6");
      formData.append("folder", "products");

      try {
        const response = await axios.post(
          "https://api.cloudinary.com/v1_1/dbliylxne/image/upload",
          formData
        );
        imageUrls.push(response.data.secure_url);
      } catch (error) {
        console.error("Image upload failed:", error);
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Image upload failed.",
        });
        return;
      }
    }
    
    if (imageUrls.length > 0) {
      Images.push(...imageUrls);
      data.productImage = Images;
      data.productSpicility = specifications;
    

      dispatch(updateProductAsync({ id, data })).then((res) => {
        if (res?.payload?.status === 1) {
          setIsLoading(false);
          onClose();
        }
      });
      setSelectedFiles([]);
      setSelectedFilesDuplicate([]);
      setValue("productImage", "");
    } else {
      data.productImage = Images;
      data.productSpicility = specifications;
      dispatch(updateProductAsync({ id, data })).then((res) => {
        if (res?.payload?.status === 1) {
          setIsLoading(false);
          onClose();
        }
      });
      setSelectedFiles([]);
      setSelectedFilesDuplicate([]);
      
    }
  };
  return (
    <>
      
        <Button
        id="click-button"
        onClick={onOpen}
        ref={containerRef}
        variant="ghost"
      >
       {editIcon()}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay
          style={{
            backdropFilter: "blur(2px)", // Apply the backdrop blur effect
          }}
        />
        
        <ModalContent style={style.form_mainbox} maxWidth="100%">
          <ModalHeader
            style={{
              color: "#404040",
              fontFamily: "Inter",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "normal",
            }}
          >
            Edit Product
          </ModalHeader>
          <ModalCloseButton
            backgroundColor="#8E8E8E"
            borderRadius="50%"
            color="white"
          />
        {isLoading ? <CenteredSpinner/> :( <ModalContent style={style.form_mainbox} maxWidth="100%">
          <ModalHeader
            style={{
              color: "#404040",
              fontFamily: "Inter",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "normal",
            }}
          >
            Edit Product
          </ModalHeader>
          <ModalCloseButton
            backgroundColor="#8E8E8E"
            borderRadius="50%"
            color="white"
          />
          <ModalBody>
            <Box style={style.form_mainbox}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Text style={style.formText}>Product Name</Text>
                <Input
                  id="productName"
                  {...register("productName", {
                    required: "productName must be required",
                  })}
                  style={style.formInput}
                />
                <Box
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2,414px)",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Text style={style.formText}>Product Price</Text>
                    <Input
                      id="productRate"
                      {...register("productRate")}
                      style={style.formInput}
                    />
                  </Box>
                  <Box>
                    <Text style={style.formText}>Product Price / Unit</Text>
                    <Input
                      id="pricePerUnit"
                      {...register("pricePerUnit")}
                      style={style.formInput}
                    />
                  </Box>
                </Box>
                <Box
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2,414px)",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Text style={style.formText}>Product Category</Text>
                    <Select
                      id="categoryName"
                      {...register("categoryName")}
                      style={style.formInput}
                      placeholder={categoryName}
                      onChange={(e) => {
                        const selectedCategory = CategorySelector.find(
                          (el) => el.categoryName === e.target.value
                        );
                        if (selectedCategory) {
                          setCategoryId(selectedCategory._id);
                        }
                      }}
                    >
                      {CategorySelector?.length >0 &&
                        CategorySelector.map((el) => (
                          <option value={el.categoryName}>
                            {el.categoryName}
                          </option>
                        ))}
                    </Select>
                  </Box>
                  {/* <Box>
                    <Text style={style.formText}>Product Sub Category</Text>
                    <Select
                      id="subcategoryId"
                      {...register("subcategoryId")}
                      style={style.formInput}
                      placeholder={subcategoryProduct}
                    >
                      {SubCategorySelector?.status === 1 &&
                        SubCategorySelector?.data?.map((el) => (
                          <option value={el._id}>{el.subcategoryName}</option>
                        ))}
                    </Select>
                  </Box> */}
                </Box>

                <Text style={style.formText}>Product Description</Text>
                <Textarea
                  id="productDescription"
                  {...register("productDescription", {
                    required: "productDescription must be required",
                  })}
                  style={style.formInput}
                />
                <Text style={style.formText}>Product Image</Text>
                <Flex direction="row" gap={"60px"}>
                  {Images.map((el, index) => (
                    <Box key={index} position="relative">
                      <Image
                        src={el}
                        alt={`Image ${index}`}
                        w="150px"
                        h="150px"
                      />
                      <IconButton
                        icon={<DeleteIcon />}
                        size="sm"
                        colorScheme="red"
                        position="absolute"
                        top={0}
                        right={0}
                        onClick={() => handleDeleteImage(index)}
                      />
                    </Box>
                  ))}
                  <IconButton
                    w="50px"
                    h="50px"
                    borderRadius={"14px"}
                    border="1px solid #A0A0A0"
                    backgroundColor={"#FFFFFF"}
                    mt="50px"
                    ml={"50px"}
                    icon={<AddIcon w="24px" h="24px" color={"#8E8E8E"} />}
                    onClick={handleIconButtonClick}
                  />
                  <div>
                    {console.log("selectedFilesselectedFiles", selectedFiles)}
                    {selectedFiles.map((fileName, index) => (
                      <div style={{ color: "green" }} key={index}>
                        {index + 1}. {fileName}
                      </div>
                    ))}
                  </div>
                  <Input
                    multiple
                    type="file"
                    id="productImage"
                    ref={fileInputRef}
                    {...register("productImage")}
                    onChange={handleFileSelection}
                    style={{ display: "none" }}
                  />
                </Flex>
                <Text style={style.formText}>Specifiation Details</Text>
                <Box style={{ backgroundColor: "#F5F5F5" }}>
                  <ProductSpecification
                    id="productSpicility"
                    specifications={specifications}
                    onSpecificationChange={handleSpecificationChange}
                    {...register("productSpicility")}
                  />
                </Box>
                <Box style={style.btnbox}>
                  <Button style={style.cancelButton}>cancel</Button>
                  <Button style={style.formButton} type="submit">
                    Submit
                  </Button>
                </Box>
              </form>
            </Box>
          </ModalBody>
        </ModalContent>)}
        </ModalContent>
      </Modal>
   
     
    </>
  );
}

export default AdminEditModal;
