import { Box, Button, Input, Select, Text, Textarea } from "@chakra-ui/react";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import{useDispatch} from 'react-redux';
import { createCategoryAsync } from "../../features/Category/CategorySlice";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import CenteredSpinner from "../../component/constant/CenteredSpinner ";

const style = {
  form_mainbox: {
    width: "600px",
    borderRadius: "28px",
    background: "#f3ffe1",
    padding: "24px",
    margin: "auto",
    marginTop: "20px",
  },
  formheading: {
    color: "#404040",
    fontFamily: "Inter",
    fontSize: "40px",
    fontWeight: "700",
    letterSpacing: "1.2px",
    marginTop: "30px",
    marginLeft: "50px",
  },
  formInput: {
    width: "100%",
    height: "50px",
    borderRadius: "14px",
    border: "1px solid #A0A0A0",
    backgroundColor: "#FFF",
    paddingLeft: "10px",
    marginBottom: "12px",
  },
  formText: {
    color: "#404040",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "500",
    marginBottom: "6px",
  },
  formButton: {
    borderRadius: "14px",
    background: "#52771A",
    color: "#f3ffe1",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "500",
    padding: "12px 16px",
  },
  btnbox: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  errorstyle: {
    color: "red",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "500",
    marginTop: "4px",
  },
};
const AddCategory = () => {
   const { register, handleSubmit,setValue, formState: { errors } } = useForm();
   const[isloding, setLoding] =  useState(false);
   const dispatch = useDispatch();
   const onSubmit = (data) =>  handleFormSubmit (data);
   const navigate = useNavigate(); 
   const handleFormSubmit = async (data) => {
    setLoding(true);
     let imageUrls = null;

     const formData = new FormData();
     formData.append("file", data.categoryImage[0]);
     formData.append("upload_preset", "mun8qyj6");
     formData.append("folder", "category");

     try {
     
       const response = await axios.post(
         "https://api.cloudinary.com/v1_1/dbliylxne/image/upload",
         formData
       );
       imageUrls =  response.data.secure_url;
       console.log("Upload to Cloudinary:", response.data.secure_url);
     } catch (error) {
       console.error("Image upload failed:", error);
      
       Swal.fire({
         icon: "error",
         title: "Oops...",
         text: "Image upload failed.",
       });
       return;
     }
        
     if(imageUrls){
        data.categoryImage = imageUrls;
        dispatch(createCategoryAsync(data)).then((res) =>{
          if(res?.payload?.status === 1){
            setLoding(false);
         toast.success("Category Added Sucessfully ");
         navigate('/categorylist');
         setValue("categoryName", "");
         setValue("categoryDescription", "");
          setValue("categoryImage", "");
          }
        });

       
     }
    
        };


   return (
    <>
      {isloding ? <CenteredSpinner/> : (  <Box>
    
    <Toaster toastOptions={{ duration: 3000 }} />

    <Box display={"flex"} justifyContent="space-between">
      <Text style={style.formheading}>Add Category</Text>
      <Link to={"/categorylist"}>
        <Button style={style.formButton} mr="50px" mt={"45px"}>
          SHOW CATEGORY LIST
        </Button>
      </Link>
    </Box>

    <Box style={style.form_mainbox}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text style={style.formText}>Category Name</Text>
        <Input
          id="categoryName"
          {...register("categoryName", {
            required: "category name must be required",
          })}
          style={style.formInput}
        />{" "}
        {errors.categoryName && (
          <span style={style.errorstyle}>{errors.categoryName.message}</span>
        )}
        <Text style={style.formText}>Category Image</Text>
        <Input
          id="categoryImage"
          {...register("categoryImage", {
            required: "category image must be required",
          })}
          type="file"
          style={style.formInput}
        />
        {errors.categoryImage && (
          <span style={style.errorstyle}>
            {errors.categoryImage.message}
          </span>
        )}
        <Text style={style.formText}>Category Description</Text>
        <Textarea
          id="categoryDescription"
          {...register("categoryDescription", {
            required: "category description must be required",
          })}
          style={style.formInput}
        />
        {errors.categoryDescription && (
          <span style={style.errorstyle}>
            {errors.categoryDescription.message}
          </span>
        )}
        <Box style={style.btnbox}>
          <Button type="submit" style={style.formButton}>
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  </Box>)}
    </>
   
   );
};

export default AddCategory;
