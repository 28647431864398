

export const heartIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 21.35L10.55 20.03C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3C9.24 3 10.91 3.81 12 5.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5C22 12.27 18.6 15.36 13.45 20.03L12 21.35Z"
        fill="#52771A"
      />
    </svg>
  );
};

export const nameIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <path
        d="M11.006 3.61256C11.006 4.60533 10.6499 5.46467 9.94761 6.16711C9.24529 6.86943 8.38583 7.22546 7.39306 7.22546H7.39134C6.39983 7.22511 5.54095 6.86909 4.83862 6.16711C4.13618 5.46467 3.78027 4.60533 3.78027 3.61256C3.78027 2.62012 4.13618 1.76067 4.83862 1.05835C5.5406 0.356255 6.39948 0.000343324 7.39134 0H7.39306C8.38549 0 9.24494 0.356026 9.94761 1.05835C10.6499 1.76067 11.006 2.62012 11.006 3.61256Z"
        fill="#FFBB85"
      />
      <path
        d="M13.7452 12.4203C13.7452 13.2035 13.496 13.8376 13.0053 14.3047C12.5203 14.7662 11.8789 15 11.0989 15H3.87634C3.09643 15 2.45499 14.7662 1.97033 14.3047C1.47926 13.8373 1.23047 13.2033 1.23047 12.4203C1.23047 12.1191 1.24043 11.8211 1.26034 11.5345C1.28059 11.2415 1.32156 10.9227 1.38199 10.5864C1.44287 10.2473 1.52115 9.92672 1.61488 9.63375C1.71215 9.33048 1.84376 9.03133 2.00661 8.74477C2.17575 8.44734 2.37442 8.18825 2.59712 7.97493C2.83058 7.75166 3.116 7.57233 3.44593 7.44164C3.77472 7.31163 4.13921 7.24571 4.52923 7.24571C4.68224 7.24571 4.83044 7.30831 5.11608 7.49439C5.29484 7.61101 5.50083 7.74376 5.72823 7.8891C5.92461 8.0143 6.19057 8.13171 6.51936 8.2378C6.80832 8.3313 7.10163 8.38348 7.3914 8.39252C7.42332 8.39367 7.45525 8.39424 7.48718 8.39424C7.80899 8.39424 8.13446 8.34148 8.45535 8.2378C8.78414 8.13171 9.05045 8.0143 9.24671 7.8891C9.47651 7.74227 9.6825 7.60963 9.85863 7.49474C10.1445 7.30831 10.2925 7.24571 10.4458 7.24571C10.8355 7.24571 11.2 7.31163 11.529 7.44164C11.8589 7.57233 12.1444 7.752 12.3776 7.97493C12.6006 8.18825 12.7993 8.44734 12.9683 8.74477C13.1313 9.03156 13.2631 9.33048 13.3601 9.63341C13.4539 9.92672 13.5324 10.2473 13.5933 10.5866C13.6534 10.9233 13.6945 11.2421 13.7146 11.5343C13.7349 11.8199 13.7448 12.1179 13.7452 12.4203Z"
        fill="#6AA9FF"
      />
      <path
        d="M7.39307 7.22546H7.39136V0H7.39307C8.38551 0 9.24496 0.356026 9.94762 1.05835C10.6499 1.76067 11.006 2.62012 11.006 3.61256C11.006 4.60533 10.6499 5.46467 9.94762 6.16711C9.2453 6.86943 8.38585 7.22546 7.39307 7.22546Z"
        fill="#F5A86C"
      />
      <path
        d="M13.7451 12.4203C13.7451 13.2035 13.496 13.8376 13.0053 14.3047C12.5203 14.7662 11.8788 15 11.0989 15H7.39136V8.39252C7.42329 8.39367 7.45522 8.39424 7.48714 8.39424C7.80895 8.39424 8.13442 8.34148 8.45531 8.2378C8.7841 8.13171 9.05041 8.0143 9.24667 7.8891C9.47647 7.74227 9.68246 7.60963 9.85859 7.49474C10.1445 7.30831 10.2924 7.24571 10.4458 7.24571C10.8355 7.24571 11.2 7.31163 11.529 7.44164C11.8589 7.57233 12.1443 7.752 12.3775 7.97493C12.6006 8.18825 12.7993 8.44734 12.9683 8.74477C13.1313 9.03156 13.2631 9.33048 13.36 9.63341C13.4539 9.92672 13.5324 10.2473 13.5933 10.5866C13.6533 10.9233 13.6944 11.2421 13.7146 11.5343C13.7348 11.8199 13.7448 12.1179 13.7451 12.4203Z"
        fill="#2682FF"
      />
    </svg>
  );
};

export const emailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <g clip-path="url(#clip0_216_229)">
        <path
          d="M13.0434 5.86963L7.49997 9.45656L1.9565 5.86963V0.326157C1.95268 0.149887 2.09247 0.00391308 2.26874 9.17684e-05C2.27336 5.69044e-08 2.27798 5.69044e-08 2.28259 9.17684e-05H12.7173C12.8936 -0.00372954 13.0396 0.136069 13.0434 0.312339C13.0435 0.316955 13.0435 0.321571 13.0434 0.326187V5.86963Z"
          fill="white"
        />
        <path
          d="M1.95651 5.86963L0 4.56528L1.95651 2.60876V5.86963Z"
          fill="#F6B445"
        />
        <path
          d="M15 4.56528L13.0435 5.86963V2.60876L15 4.56528Z"
          fill="#F6B445"
        />
        <path
          d="M13.0434 5.84028L15 4.56528V13.6957C14.9993 14.0416 14.8622 14.3732 14.6184 14.6185L12.3913 12.3914L8.80433 8.8044L8.71301 8.66417L13.0434 5.84028Z"
          fill="#FED049"
        />
        <path
          d="M12.3913 12.3913L14.6185 14.6185C14.3732 14.8623 14.0415 14.9994 13.6957 15H1.30442C0.958549 14.9994 0.62692 14.8623 0.381592 14.6185L2.60874 12.3913L6.19567 8.8044L6.28699 8.66417L7.50003 9.45656L8.71306 8.66417L8.80438 8.8044L12.3913 12.3913Z"
          fill="#F6B445"
        />
        <path
          d="M6.28692 8.66417L6.1956 8.8044L2.60867 12.3913L0.38152 14.6185C0.13772 14.3732 0.00061141 14.0415 0 13.6956V4.56528L6.28692 8.66417Z"
          fill="#FED049"
        />
        <path
          d="M11.087 7.82614C13.2481 7.82614 15 6.07422 15 3.91312C15 1.75201 13.2481 9.15527e-05 11.087 9.15527e-05C8.92587 9.15527e-05 7.17395 1.75201 7.17395 3.91312C7.17395 6.07422 8.92587 7.82614 11.087 7.82614Z"
          fill="#49AAEE"
        />
        <path
          d="M11.0869 5.86963C10.9068 5.86963 10.7608 5.72363 10.7608 5.54354V3.21788L10.2896 3.53224C10.1398 3.63221 9.9373 3.59176 9.83734 3.44191C9.73737 3.29208 9.77782 3.08958 9.92767 2.98961L10.9059 2.33745C11.0557 2.23749 11.2582 2.27793 11.3582 2.42773C11.3939 2.48132 11.413 2.54432 11.413 2.60877V5.54354C11.413 5.72363 11.267 5.86963 11.0869 5.86963Z"
          fill="#F1F2F2"
        />
      </g>
      <defs>
        <clipPath id="clip0_216_229">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const phoneIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_217_245)">
        <path
          d="M12.6875 0.96875H10.8125H8.9375C7.12841 0.96875 5.625 2.44091 5.625 4.25C5.625 5.90025 6.88078 7.26988 8.46875 7.49784V8.9375C8.46875 9.127 8.58272 9.29822 8.75806 9.37053C8.93097 9.44269 9.13378 9.404 9.26894 9.26891L10.8125 7.72534L11.0066 7.53125H12.6875C14.4966 7.53125 16 6.05909 16 4.25C16 2.44091 14.4966 0.96875 12.6875 0.96875Z"
          fill="#FED843"
        />
        <path
          d="M12.6875 7.53125C14.4966 7.53125 16 6.05909 16 4.25C16 2.44091 14.4966 0.96875 12.6875 0.96875H10.8125V7.72534L11.0066 7.53125H12.6875Z"
          fill="#FABE2C"
        />
        <path
          d="M11.9375 11.75V13.625C11.9375 14.4406 11.2716 15.0312 10.5378 15.0312C7.8375 15.0312 5.20312 13.7547 3.24375 11.8031C1.28438 9.85156 0 7.225 0 4.52469C0 3.74906 0.630938 3.125 1.40625 3.125H3.28125C3.48312 3.125 3.66219 3.25406 3.72563 3.44531L4.66313 6.25125C4.70906 6.38875 4.68875 6.53938 4.60875 6.65969L3.81375 7.8525C4.115 8.48438 4.64813 9.175 5.26375 9.78312C5.87969 10.3909 6.57812 10.9162 7.21 11.2175L8.40281 10.4225C8.52375 10.3428 8.67375 10.3219 8.81125 10.3681L11.6237 11.3056C11.815 11.3691 11.9375 11.5481 11.9375 11.75Z"
          fill="#FF641A"
        />
        <path
          d="M11.9375 11.75V13.625C11.9375 14.4406 11.2716 15.0312 10.5378 15.0312C7.83752 15.0312 5.20315 13.7547 3.24377 11.8031L5.26377 9.78312C5.87971 10.3909 6.57815 10.9162 7.21002 11.2175L8.40284 10.4225C8.52377 10.3428 8.67377 10.3219 8.81127 10.3681L11.6238 11.3056C11.815 11.3691 11.9375 11.5481 11.9375 11.75Z"
          fill="#F03800"
        />
        <path
          d="M8.9375 4.71872C9.19638 4.71872 9.40625 4.50885 9.40625 4.24997C9.40625 3.99109 9.19638 3.78122 8.9375 3.78122C8.67862 3.78122 8.46875 3.99109 8.46875 4.24997C8.46875 4.50885 8.67862 4.71872 8.9375 4.71872Z"
          fill="#613D5C"
        />
        <path
          d="M10.8125 4.71869C11.0714 4.71869 11.2812 4.50882 11.2812 4.24994C11.2812 3.99105 11.0714 3.78119 10.8125 3.78119C10.5536 3.78119 10.3438 3.99105 10.3438 4.24994C10.3438 4.50882 10.5536 4.71869 10.8125 4.71869Z"
          fill="#613D5C"
        />
        <path
          d="M11.2812 4.24994C11.2812 3.99106 11.0714 3.78119 10.8125 3.78119V4.71869C11.0714 4.71869 11.2812 4.50881 11.2812 4.24994Z"
          fill="#4B2746"
        />
        <path
          d="M12.6875 4.71872C12.9464 4.71872 13.1562 4.50885 13.1562 4.24997C13.1562 3.99108 12.9464 3.78122 12.6875 3.78122C12.4286 3.78122 12.2188 3.99108 12.2188 4.24997C12.2188 4.50885 12.4286 4.71872 12.6875 4.71872Z"
          fill="#4B2746"
        />
      </g>
      <defs>
        <clipPath id="clip0_217_245">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const stateIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <g clip-path="url(#clip0_217_257)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.4956 11.8541C-6.00177 -3.95431 20.993 -3.95431 7.4956 11.8541V11.8541Z"
          fill="#FF4A4A"
        />
        <path
          d="M7.49557 4.88576C8.27207 4.88576 8.90155 4.25628 8.90155 3.47978C8.90155 2.70328 8.27207 2.07381 7.49557 2.07381C6.71908 2.07381 6.0896 2.70328 6.0896 3.47978C6.0896 4.25628 6.71908 4.88576 7.49557 4.88576Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.68894 8.70825H5.20212C5.78208 9.67486 6.52901 10.7206 7.49561 11.8541C8.46222 10.7206 9.21793 9.67486 9.7979 8.70825H12.3023C12.8735 8.70825 13.2777 9.17398 13.3392 9.73637L13.7961 13.9631C13.8577 14.5255 13.3392 15 12.768 15H2.23199C1.66082 15 1.13357 14.5255 1.19509 13.9631L1.66082 9.73637C1.72233 9.17398 2.12654 8.70825 2.68894 8.70825Z"
          fill="#44C868"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.4622 10.8963L13.6731 12.8032H10.3603L10.9403 15H8.9719L8.38314 12.8032H1.3269L1.52901 10.8963H6.72233C6.96838 11.2126 7.22321 11.529 7.49562 11.8541C7.77682 11.529 8.03165 11.2126 8.2777 10.8963H13.4622Z"
          fill="#FFBB24"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.97189 15L8.38314 12.8032H1.3269L1.19509 13.9631C1.13357 14.5255 1.66082 15 2.23199 15H8.97189Z"
          fill="#1EA4E9"
        />
      </g>
      <defs>
        <clipPath id="clip0_217_257">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const PincodeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
    >
      <path
        d="M0.335083 5.76758C0.335083 4.97177 0.980105 4.32675 1.77591 4.32675H7.41859L8.03614 5.32662C8.23986 5.657 8.72036 5.657 8.92443 5.32662L9.54164 4.32675H15.38C16.1758 4.32675 16.8208 4.97177 16.8208 5.76758V13.4304C16.8208 14.253 16.154 14.9198 15.3314 14.9198H10.4152C9.93769 14.9198 9.49439 15.1681 9.24509 15.5753L8.57796 16.6649L7.91048 15.5749C7.66119 15.1681 7.21822 14.9198 6.74107 14.9198H1.8245C1.00188 14.9198 0.335083 14.253 0.335083 13.4304V5.76758Z"
        fill="#A0C8FF"
      />
      <path
        d="M10.165 3.31677C10.3617 2.99288 10.4586 2.6122 10.4458 2.2338C10.4114 1.21604 9.58961 0.383542 8.57232 0.337101C7.44415 0.285566 6.51331 1.18498 6.51331 2.30182C6.51331 2.66444 6.61497 3.02465 6.80519 3.33282L8.03593 5.32669C8.23989 5.6571 8.72035 5.6571 8.92435 5.32669L10.1579 3.32833C10.1603 3.32444 10.1626 3.32062 10.165 3.31677Z"
        fill="#FF7D95"
      />
      <path
        d="M8.48017 2.97097C8.8483 2.97097 9.14673 2.67253 9.14673 2.3044C9.14673 1.93626 8.8483 1.63783 8.48017 1.63783C8.11203 1.63783 7.8136 1.93626 7.8136 2.3044C7.8136 2.67253 8.11203 2.97097 8.48017 2.97097Z"
        fill="white"
      />
      <path
        d="M15.38 3.99167H10.1423L10.444 3.50286C10.6791 3.12074 10.7958 2.67064 10.7807 2.2225C10.7405 1.03188 9.77722 0.0566734 8.58767 0.00235752C7.2927 -0.0567834 6.1783 1.00464 6.1783 2.30178C6.1783 2.71393 6.28948 3.13301 6.5056 3.48521L6.8182 3.99167H1.7759C0.796644 3.99167 0 4.78835 0 5.76758V8.10339C0 8.28846 0.150047 8.43847 0.335076 8.43847C0.520105 8.43847 0.670152 8.28846 0.670152 8.10339V5.76758C0.670152 5.15787 1.1662 4.66183 1.7759 4.66183H7.23171L7.75081 5.50267C8.08153 6.03835 8.87781 6.03343 9.20953 5.50263L9.72857 4.66183H15.38C15.9897 4.66183 16.4857 5.15787 16.4857 5.76758V13.4304C16.4857 14.0669 15.9679 14.5848 15.3314 14.5848H10.4152C9.82517 14.5848 9.2673 14.8973 8.95933 15.4003L8.57792 16.0232L8.19616 15.3998C7.88803 14.8971 7.33046 14.5848 6.74103 14.5848H1.82449C1.18798 14.5848 0.670152 14.0669 0.670152 13.4304V11.0511C0.670152 10.866 0.520105 10.716 0.335076 10.716C0.150047 10.716 0 10.866 0 11.0511V13.4304C0 14.4365 0.818491 15.2549 1.82449 15.2549H6.74106C7.09902 15.2549 7.43765 15.4446 7.62472 15.7499L8.2922 16.8399C8.35308 16.9394 8.46134 17 8.57795 17C8.69459 17 8.80282 16.9394 8.8637 16.8399L9.53084 15.7502C9.71791 15.4447 10.0567 15.2549 10.4152 15.2549H15.3314C16.3374 15.2549 17.1559 14.4365 17.1559 13.4304V5.76758C17.1559 4.78835 16.3593 3.99167 15.38 3.99167ZM7.35321 1.12175C7.6785 0.810997 8.10626 0.650864 8.55711 0.67184C9.39993 0.710306 10.0824 1.40137 10.111 2.24509C10.1216 2.56294 10.0412 2.87366 9.87825 3.1436L8.63924 5.15064C8.56703 5.26754 8.39342 5.26764 8.32121 5.1505L7.08237 3.14383C6.9344 2.88492 6.84845 2.60154 6.84845 2.30175C6.84845 1.85178 7.02769 1.43273 7.35321 1.12175Z"
        fill="black"
      />
      <path
        d="M8.98753 8.96836V6.829C8.98753 6.64394 8.83749 6.49393 8.65246 6.49393C8.46743 6.49393 8.31738 6.64394 8.31738 6.829V8.96836C8.31738 9.15343 8.46743 9.30344 8.65246 9.30344C8.83749 9.30344 8.98753 9.15343 8.98753 8.96836Z"
        fill="black"
      />
      <path
        d="M5.55379 9.12803C5.61236 9.23612 5.72545 9.30344 5.84839 9.30344H7.27558C7.46061 9.30344 7.61066 9.15343 7.61066 8.96836C7.61066 8.7833 7.46061 8.63329 7.27558 8.63329H6.46664L7.52307 7.01192C7.59018 6.90892 7.59548 6.77743 7.53691 6.66934C7.47834 6.56124 7.36525 6.49393 7.24231 6.49393H5.89222C5.70719 6.49393 5.55714 6.64394 5.55714 6.829C5.55714 7.01407 5.70719 7.16408 5.89222 7.16408H6.62406L5.56763 8.78545C5.50051 8.88845 5.49522 9.01993 5.55379 9.12803Z"
        fill="black"
      />
      <path
        d="M10.4314 8.96837V8.37237C10.5308 8.3719 10.6299 8.37153 10.692 8.37153C11.2162 8.37153 11.6426 7.95037 11.6426 7.43271C11.6426 6.91505 11.2162 6.4939 10.692 6.4939H10.0963C9.91396 6.4939 9.76123 6.64659 9.76123 6.82897V8.96833C9.76123 9.1534 9.91128 9.30341 10.0963 9.30341C10.2813 9.30341 10.4314 9.15343 10.4314 8.96837ZM10.692 7.16408C10.844 7.16408 10.9725 7.28709 10.9725 7.43275C10.9725 7.5784 10.844 7.70141 10.692 7.70141C10.6303 7.70141 10.5324 7.70178 10.4337 7.70225C10.4333 7.59935 10.4327 7.27224 10.4324 7.16412H10.692V7.16408Z"
        fill="black"
      />
      <path
        d="M13.5117 10.927C13.6967 10.927 13.8468 10.7769 13.8468 10.5919C13.8468 10.4068 13.6967 10.2568 13.5117 10.2568H12.6185C12.4335 10.2568 12.2834 10.4068 12.2834 10.5919V12.7117C12.2834 12.8968 12.4335 13.0468 12.6185 13.0468H13.5117C13.6967 13.0468 13.8468 12.8968 13.8468 12.7117C13.8468 12.5266 13.6967 12.3766 13.5117 12.3766H12.9536V11.9869H13.4459C13.6309 11.9869 13.7809 11.8369 13.7809 11.6518C13.7809 11.4667 13.6309 11.3167 13.4459 11.3167H12.9536V10.927H13.5117Z"
        fill="black"
      />
      <path
        d="M9.55448 12.6561C9.55448 12.8511 9.69079 13.0249 9.89501 13.0249C9.91277 13.0248 10.3326 13.0232 10.5006 13.0203C11.1672 13.0086 11.651 12.4331 11.651 11.6517C11.651 10.8304 11.1794 10.2786 10.4775 10.2786H9.88952C9.70536 10.2786 9.55444 10.4326 9.55444 10.6159V12.6561H9.55448ZM10.4775 10.9488C10.9446 10.9488 10.9809 11.4869 10.9809 11.6518C10.9809 11.9952 10.8287 12.3443 10.4889 12.3502C10.4272 12.3513 10.3278 12.3522 10.2277 12.353C10.227 12.1149 10.2256 11.1971 10.2252 10.9489H10.4775V10.9488Z"
        fill="black"
      />
      <path
        d="M4.71382 10.247C3.93926 10.247 3.30908 10.8772 3.30908 11.6518C3.30908 12.4264 3.93926 13.0565 4.71382 13.0565C5.07118 13.0565 5.41309 12.9104 5.64436 12.6349C5.76338 12.4932 5.74495 12.2819 5.60321 12.1629C5.46151 12.0439 5.25011 12.0623 5.13113 12.204C5.02651 12.3285 4.87422 12.3864 4.71382 12.3864C4.30878 12.3864 3.97923 12.0568 3.97923 11.6518C3.97923 11.2467 4.30878 10.9172 4.71382 10.9172C4.86109 10.9172 5.00303 10.9605 5.12429 11.0424C5.27765 11.1461 5.4859 11.1057 5.58954 10.9524C5.69315 10.799 5.65284 10.5908 5.49948 10.4872C5.267 10.33 4.99529 10.247 4.71382 10.247Z"
        fill="black"
      />
      <path
        d="M6.13818 11.6518C6.13818 12.4264 6.76836 13.0566 7.54292 13.0566C8.31752 13.0566 8.9477 12.4264 8.9477 11.6518C8.9477 10.8772 8.31752 10.247 7.54292 10.247C6.76836 10.247 6.13818 10.8772 6.13818 11.6518ZM8.27754 11.6518C8.27754 12.0569 7.948 12.3864 7.54292 12.3864C7.13788 12.3864 6.80834 12.0569 6.80834 11.6518C6.80834 11.2467 7.13788 10.9172 7.54292 10.9172C7.948 10.9172 8.27754 11.2467 8.27754 11.6518Z"
        fill="black"
      />
      <path
        d="M8.48016 3.30601C9.03243 3.30601 9.4818 2.85667 9.4818 2.30436C9.4818 1.75206 9.03246 1.30272 8.48016 1.30272C7.92785 1.30272 7.47852 1.75206 7.47852 2.30436C7.47852 2.85667 7.92789 3.30601 8.48016 3.30601ZM8.48016 1.9729C8.66294 1.9729 8.81165 2.12161 8.81165 2.3044C8.81165 2.48718 8.66294 2.63589 8.48016 2.63589C8.29737 2.63589 8.14867 2.48718 8.14867 2.3044C8.14867 2.12161 8.29737 1.9729 8.48016 1.9729Z"
        fill="black"
      />
      <path
        d="M0.0565471 9.76316C0.156701 9.91613 0.369944 9.95587 0.520963 9.85565C0.673288 9.75459 0.714067 9.54289 0.613444 9.39123C0.512485 9.23907 0.300784 9.19725 0.149062 9.29841C-0.00296241 9.39977 -0.0444448 9.61138 0.0565471 9.76316Z"
        fill="black"
      />
    </svg>
  );
};

export const locationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="14"
      viewBox="0 0 10 14"
      fill="none"
    >
      <path
        d="M5 6.65C4.5264 6.65 4.0722 6.46563 3.73731 6.13744C3.40242 5.80925 3.21429 5.36413 3.21429 4.9C3.21429 4.43587 3.40242 3.99075 3.73731 3.66256C4.0722 3.33437 4.5264 3.15 5 3.15C5.4736 3.15 5.9278 3.33437 6.26269 3.66256C6.59758 3.99075 6.78571 4.43587 6.78571 4.9C6.78571 5.12981 6.73953 5.35738 6.64979 5.5697C6.56004 5.78202 6.42851 5.97493 6.26269 6.13744C6.09687 6.29994 5.90002 6.42884 5.68336 6.51679C5.46671 6.60473 5.2345 6.65 5 6.65ZM5 0C3.67392 0 2.40215 0.516248 1.46447 1.43518C0.526784 2.3541 0 3.60044 0 4.9C0 8.575 5 14 5 14C5 14 10 8.575 10 4.9C10 3.60044 9.47322 2.3541 8.53553 1.43518C7.59785 0.516248 6.32608 0 5 0Z"
        fill="#52771A"
      />
    </svg>
  );
};

export const contactIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clip-path="url(#clip0_160_85)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.80005 0C2.48179 0 2.17656 0.126428 1.95152 0.351472C1.72648 0.576515 1.60005 0.88174 1.60005 1.2V3.2H0.800049V4H1.60005V8H0.800049V8.8H1.60005V10.8C1.60005 11.1183 1.72648 11.4235 1.95152 11.6485C2.17656 11.8736 2.48179 12 2.80005 12H10C10.3183 12 10.6235 11.8736 10.8486 11.6485C11.0736 11.4235 11.2 11.1183 11.2 10.8V1.2C11.2 0.88174 11.0736 0.576515 10.8486 0.351472C10.6235 0.126428 10.3183 0 10 0L2.80005 0ZM4.80005 4C4.80005 3.57565 4.96862 3.16869 5.26868 2.86863C5.56874 2.56857 5.9757 2.4 6.40005 2.4C6.8244 2.4 7.23136 2.56857 7.53142 2.86863C7.83148 3.16869 8.00005 3.57565 8.00005 4C8.00005 4.42435 7.83148 4.83131 7.53142 5.13137C7.23136 5.43143 6.8244 5.6 6.40005 5.6C5.9757 5.6 5.56874 5.43143 5.26868 5.13137C4.96862 4.83131 4.80005 4.42435 4.80005 4ZM4.00005 8.8C4.00005 8.16348 4.25291 7.55303 4.70299 7.10294C5.15308 6.65286 5.76353 6.4 6.40005 6.4C7.03657 6.4 7.64702 6.65286 8.09711 7.10294C8.54719 7.55303 8.80005 8.16348 8.80005 8.8V9.6H4.00005V8.8Z"
          fill="#52771A"
        />
      </g>
      <defs>
        <clipPath id="clip0_160_85">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const editIcon =()=>{
  return (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <g clip-path="url(#clip0_182_253)">
    <path
      d="M16.2114 8.67152C15.8878 8.67152 15.6255 8.93387 15.6255 9.25747V17.8515C15.6255 18.39 15.1874 18.8281 14.6489 18.8281H2.1485C1.61001 18.8281 1.17191 18.39 1.17191 17.8515V5.3511C1.17191 4.81261 1.61001 4.37451 2.1485 4.37451H10.7425C11.0661 4.37451 11.3285 4.11216 11.3285 3.78855C11.3285 3.46495 11.0661 3.2026 10.7425 3.2026H2.1485C0.963819 3.2026 0 4.16642 0 5.3511V17.8515C0 19.0362 0.963819 20 2.1485 20H14.6489C15.8336 20 16.7974 19.0362 16.7974 17.8515V9.25747C16.7974 8.93387 16.535 8.67152 16.2114 8.67152Z"
      fill="black"
    />
    <path
      d="M19.5997 1.50473L18.4948 0.399817C17.9618 -0.133247 17.0944 -0.133247 16.5612 0.399817L7.72218 9.23892C7.64038 9.32072 7.58464 9.4249 7.5619 9.53834L7.00943 12.3006C6.97103 12.4927 7.03114 12.6913 7.1697 12.8298C7.28068 12.9408 7.43018 13.0015 7.58401 13.0015C7.62226 13.0015 7.6607 12.9977 7.6989 12.9901L10.4611 12.4376C10.5746 12.4149 10.6788 12.3591 10.7606 12.2773L19.5997 3.43827C19.5997 3.43827 19.5997 3.43827 19.5997 3.43823C20.1328 2.90521 20.1328 2.03784 19.5997 1.50473ZM10.0574 11.3232L8.33099 11.6686L8.67631 9.94219L15.8707 2.7477L17.2518 4.12884L10.0574 11.3232ZM18.771 2.60961L18.0805 3.30018L16.6993 1.91904L17.3899 1.22851C17.466 1.15234 17.59 1.1523 17.6661 1.22848L18.771 2.33339C18.8472 2.40953 18.8472 2.53348 18.771 2.60961Z"
      fill="black"
    />
  </g>
  <defs>
    <clipPath id="clip0_182_253">
      <rect width="20" height="20" fill="white" />
    </clipPath>
  </defs>
</svg>)
};