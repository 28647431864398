import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createProduct,
  deleteProductById,
  fetchProduct,
  fetchProductByAdmin,
  fetchProductByCategory,
  fetchProductById,
  fetchProductBySubCategoryId,
  toggleProductStatus,
  updateProduct,
} from "./ProductAPI";

const initialState = {
  value: 0,
  status: "idle",
  productData: [],
  productBycategory: null,
  productById: null,
  adminproductData: [],
};

export const createProductAsync = createAsyncThunk(
  "product/createProduct",
  async (productData) => {
    const response = await createProduct(productData);

    return response.data;
  }
);

export const fetchProductAdminAsync = createAsyncThunk(
  "product/fetchProductByAdmin",
  async () => {
    const response = await fetchProductByAdmin();
    console.log(response.data, "pall");
    return response.data;
  }
);
export const updateProductAsync = createAsyncThunk(
  "product/updateProduct",
  async ({ id, data }) => {
    console.log(id, data, "update");
    const response = await updateProduct(id, data);

    return response.data;
  }
);
export const fetchProductByIdAsync = createAsyncThunk(
  "product/fetchProductById",
  async (productId) => {
    const response = await fetchProductById(productId);

    return response.data;
  }
);
export const deleteProductAsync = createAsyncThunk(
  "product/deleteProductById",
  async (productId) => {
    const response = await deleteProductById(productId);
    console.log(response.data);
    return response.data;
  }
);

export const toggleProductStatusAsync = createAsyncThunk(
  "product/toggleProductStatus",
  async (productId) => {
    const response = await toggleProductStatus(productId);

    return response.data;
  }
);
export const fetchProductByCategoryAsync = createAsyncThunk(
  "product/fetchProductByCategory",
  async (category) => {
    const response = await fetchProductByCategory(category);

    return response.data;
  }
);

export const fetchProductBySubCategoryIdAsync = createAsyncThunk(
  "product/fetchProductBySubCategoryId",
  async (subcategoryId) => {
    const response = await fetchProductBySubCategoryId(subcategoryId);

    return response.data;
  }
);

export const ProductSlice = createSlice({
  name: "product",
  initialState,

  reducers: {
    increment: (state) => {
      state.value += 1;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createProductAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createProductAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.adminproductData.unshift(action.payload.data);  // Adds the product at the first index
      })
      
      .addCase(fetchProductAdminAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProductAdminAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.adminproductData = action.payload.data;
      })
      .addCase(fetchProductByIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProductByIdAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.productById = action.payload;
      })
      .addCase(updateProductAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateProductAsync.fulfilled, (state, action) => {
        state.status = "idle";
        let newArr = state.adminproductData.map(function (item) {
          if (item._id === action.payload.data.id) {
            return { ...item, ...action.payload.data.updated };
          } else {
            return item;
          }
        });
        state.adminproductData = newArr;
      })
      .addCase(deleteProductAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteProductAsync.fulfilled, (state, action) => {
        state.status = "idle";
         let newArr = state.adminproductData.filter(function (item) {
           return item._id !== action.payload.data._id;
         });
        state.adminproductData = newArr;
      })
      .addCase(toggleProductStatusAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(toggleProductStatusAsync.fulfilled, (state, action) => {
        state.status = "idle";
               state.status = "idle";
               let newArr = state.adminproductData.map(function (item) {
                 if (item._id === action.payload.data.id) {
                   return { ...item, status: action.payload.data.status };
                 } else {
                   return item;
                 }
               });
        state.adminproductData = newArr;
      })
      .addCase(fetchProductByCategoryAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProductByCategoryAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.productBycategory = action.payload;
      })
      .addCase(fetchProductBySubCategoryIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProductBySubCategoryIdAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.productBycategory = action.payload;
      });
  },
});

// export const { increment } = UserSlice.actions;

export const selectProductAdmin = (state) => state.product.adminproductData;
export const selectProductByCategory = (state) =>
  state.product.productBycategory;
export const selectProductById = (state) => state.product.productById;

export default ProductSlice.reducer;
