import { CheckIcon, DeleteIcon, RepeatClockIcon, Search2Icon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Input,
  Select,
  Stack,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { MdBuild } from "react-icons/md";
import OrderDetailModal from "./AdminModal/OrderDetailModal";
import EditCustomerDetail from "./AdminModal/EditCustomerDetail";
import Icon from "../component/constant/Icon";
import { useDispatch, useSelector } from "react-redux";
import { userSelector, getAllUsersAsync } from "../features/User/UserSlice";

const CustomersPage = () => {
  const allUser = useSelector(userSelector);
  const dispatch = useDispatch();

  // State management for filters
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [userStatus, setUserStatus] = useState("");

  useEffect(() => {
    dispatch(getAllUsersAsync({}));
  }, [dispatch]);

  const style = {
    formheading: {
      color: "#404040",
      fontFamily: "Inter",
      fontSize: "40px",
      fontWeight: "700",
      letterSpacing: "1.2px",
      marginTop: "30px",
    },
  };

  const handleSearch = () => {
    let params = { name: userName, email: userEmail, mobile: userMobile, status: userStatus };
    let queryString = Object.keys(params)
      .filter((key) => params[key]) // filter empty fields
      .map((key) => key + "=" + params[key])
      .join("&");
    dispatch(getAllUsersAsync(queryString));
  };

  const handleReset = () => {
    setUserName("");
    setUserEmail("");
    setUserMobile("");
    setUserStatus("");
    dispatch(getAllUsersAsync());
  };

  return (
    <Box ml={"30px"}>
      <HStack>
        <Text style={style.formheading}>Customers</Text>
        <Text mt={"40px"}>(Total records: {allUser?.data?.totalCount})</Text>
      </HStack>
      <Stack direction="row" spacing={4} mt={5}>
        <Input
          variant="filled"
          placeholder="Filter by User Name"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
        />
        <Input
          variant="filled"
          placeholder="Filter by User Email"
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value)}
        />
        <Input
          variant="filled"
          placeholder="Filter by User Mobile"
          value={userMobile}
          onChange={(e) => setUserMobile(e.target.value)}
        />
        <Select
          placeholder="Select status"
          value={userStatus}
          onChange={(e) => setUserStatus(e.target.value)}
        >
          <option value={"1"}>Active</option>
          <option value={"0"}>Inactive</option>
        </Select>
        <Button
          onClick={handleSearch}
          width="100%"
          leftIcon={<Search2Icon />}
          bg="#52771A"
          variant="solid"
          color={"#fff"}
        >
          Search
        </Button>
        <Button
          onClick={handleReset}
          width="100%"
          leftIcon={<RepeatClockIcon />}
          bg="#52771A"
          variant="solid"
          color={"#fff"}
        >
          Reset
        </Button>
      </Stack>

      <TableContainer mt={5}>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Created</Th>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Mobile</Th>
              <Th>COD</Th>
              <Th>Login</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {allUser?.data?.items?.map((el) => (
              <Tr key={el.userId}>
                <Td>{el.createdAt}</Td>
                <Td>{el.fullName}</Td>
                <Td>{el.email}</Td>
                <Td>{el.mobileNo}</Td>
                <Td>
                  <IconButton
                    size={"sm"}
                    isRound={true}
                    variant="solid"
                    colorScheme={el.cod === true ? "teal" : "red"}
                    aria-label="COD Status"
                    icon={
                      el.cod === true ? (
                        <Icon type={"thumbsUp"} color="#fff" size={"14px"} />
                      ) : (
                        <Icon type={"thumbsDown"} color="#fff" size={"14px"} />
                      )
                    }
                  />
                </Td>
                <Td>
                  <IconButton
                    size={"sm"}
                    isRound={true}
                    variant="solid"
                    colorScheme={el.isLogin === true ? "teal" : "red"}
                    aria-label="Login Status"
                    icon={
                      el.isLogin === true ? (
                        <Icon type={"thumbsUp"} color="#fff" size={"14px"} />
                      ) : (
                        <Icon type={"thumbsDown"} color="#fff" size={"14px"} />
                      )
                    }
                  />
                </Td>
                <Td>
                  <Flex justifyContent={"space-between"} w={"80px"}>
                    <EditCustomerDetail id={el.userId} name={el.name} email={el.email} mobile={el.mobile} />
                    <IconButton
                      size={"sm"}
                      variant="solid"
                      colorScheme="red"
                      aria-label="Delete Customer"
                      fontSize="18px"
                      icon={<DeleteIcon boxSize={4} />}
                    />
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CustomersPage;
