import { Flex, Spinner } from "@chakra-ui/react";

const CenteredSpinner = () => {
  return (
    <Flex justifyContent="center" alignItems="center" h="100vh">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="#52771A"
        size="xl"
      />
    </Flex>
  );
};

export default CenteredSpinner;
