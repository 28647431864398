import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Box,
  Text,
  Input,
  Select,
  Textarea,
  useDisclosure,
  ModalHeader,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axios from "axios";
import { updateGovermentschmeAsync } from "../../features/Govermentschme/GovermentschmeSlice";

const style = {
  form_mainbox: {
    width: "600px",
    borderRadius: "28px",
    background: "#f3ffe1",
    padding: "24px",
    margin: "auto",
    marginTop: "20px",
  },
  formheading: {
    color: "#404040",
    fontFamily: "Inter",
    fontSize: "40px",
    fontWeight: "700",
    letterSpacing: "1.2px",
    marginTop: "30px",
    marginLeft: "50px",
  },
  formInput: {
    width: "100%",
    height: "50px",
    borderRadius: "14px",
    border: "1px solid #A0A0A0",
    backgroundColor: "#FFF",
    paddingLeft: "10px",
    marginBottom: "12px",
  },
  formText: {
    color: "#404040",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "500",
    marginBottom: "6px",
  },
  formButton: {
    borderRadius: "14px",
    background: "#52771A",
    color: "#f3ffe1",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "500",
    padding: "12px 16px",
  },
  btnbox: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
};
const states = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Lakshadweep",
  "Delhi",
  "Puducherry",
];

const GovermentschmeEdit = ({
  name,
  image,
  state,
  content,
  govermentschmeId,
  currentPage,
  govermentschmeState,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue("govermentschemeName", name);
    setValue("content", content);
    setValue("statename", state);
  }, [name, content, state]);

  const onSubmit = (data) => cloudFormSubmit(data);

  const cloudFormSubmit = async (data) => {
    console.log(data, "dfata");
    let imageUrls = null;
    if (data.image[0] !== undefined) {
      const formData = new FormData();
      formData.append("file", data.image[0]);
      formData.append("upload_preset", "mun8qyj6");
      formData.append("folder", "Govermentscheme");

      try {
        const response = await axios.post(
          "https://api.cloudinary.com/v1_1/dbliylxne/image/upload",
          formData
        );
        imageUrls = response.data.secure_url;
        console.log("Upload to Cloudinary:", response.data.secure_url);
      } catch (error) {
        console.error("Image upload failed:", error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Image upload failed.",
        });
        return;
      }
    }

    if (imageUrls) {
      data.image = imageUrls;
      dispatch(
        updateGovermentschmeAsync({
          govermentschmeId,
          currentPage,
          govermentschmeState,
          data,
        }).then((res) => {
          if (res?.payload?.status === 1) {
            onClose();
          }
        })
      );
    } else {
      data.image = image;
      console.log(data, "updateData2");
      dispatch(
        updateGovermentschmeAsync({
          govermentschmeId,
          currentPage,
          govermentschmeState,
          data,
        }).then((res) => {
          if (res?.payload?.status === 1) {
            onClose();
          }
        })
      );
    }

    console.log(data, "updateData");
    // Do something with the imageUrl, e.g., save it to a database
  };

  return (
    <>
      <Button onClick={onOpen} variant="ghost">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <g clip-path="url(#clip0_182_253)">
            <path
              d="M16.2114 8.67152C15.8878 8.67152 15.6255 8.93387 15.6255 9.25747V17.8515C15.6255 18.39 15.1874 18.8281 14.6489 18.8281H2.1485C1.61001 18.8281 1.17191 18.39 1.17191 17.8515V5.3511C1.17191 4.81261 1.61001 4.37451 2.1485 4.37451H10.7425C11.0661 4.37451 11.3285 4.11216 11.3285 3.78855C11.3285 3.46495 11.0661 3.2026 10.7425 3.2026H2.1485C0.963819 3.2026 0 4.16642 0 5.3511V17.8515C0 19.0362 0.963819 20 2.1485 20H14.6489C15.8336 20 16.7974 19.0362 16.7974 17.8515V9.25747C16.7974 8.93387 16.535 8.67152 16.2114 8.67152Z"
              fill="black"
            />
            <path
              d="M19.5997 1.50473L18.4948 0.399817C17.9618 -0.133247 17.0944 -0.133247 16.5612 0.399817L7.72218 9.23892C7.64038 9.32072 7.58464 9.4249 7.5619 9.53834L7.00943 12.3006C6.97103 12.4927 7.03114 12.6913 7.1697 12.8298C7.28068 12.9408 7.43018 13.0015 7.58401 13.0015C7.62226 13.0015 7.6607 12.9977 7.6989 12.9901L10.4611 12.4376C10.5746 12.4149 10.6788 12.3591 10.7606 12.2773L19.5997 3.43827C19.5997 3.43827 19.5997 3.43827 19.5997 3.43823C20.1328 2.90521 20.1328 2.03784 19.5997 1.50473ZM10.0574 11.3232L8.33099 11.6686L8.67631 9.94219L15.8707 2.7477L17.2518 4.12884L10.0574 11.3232ZM18.771 2.60961L18.0805 3.30018L16.6993 1.91904L17.3899 1.22851C17.466 1.15234 17.59 1.1523 17.6661 1.22848L18.771 2.33339C18.8472 2.40953 18.8472 2.53348 18.771 2.60961Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_182_253">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay
          style={{
            backdropFilter: "blur(2px)", // Apply the backdrop blur effect
          }}
        />
        <ModalContent style={style.form_mainbox}>
          <ModalHeader
            style={{
              color: "#404040",
              fontFamily: "Inter",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "normal",
            }}
          >
            Edit Goverment Schme
          </ModalHeader>
          <ModalCloseButton
            backgroundColor="#8E8E8E"
            borderRadius="50%"
            color="white"
          />
          <ModalBody>
            <Box>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Text style={style.formText}>Goverment Schme Name</Text>
                <Input
                  id="govermentschemeName"
                  {...register("govermentschemeName", {
                    required: "govermentschemeName must be required",
                  })}
                  style={style.formInput}
                />
                {errors.govermentschemeName && (
                  <span style={style.errorstyle}>
                    goverment scheme name is Required
                  </span>
                )}
                <Text style={style.formText}>State Name</Text>
                <Select
                  id="statename"
                  {...register("statename", {
                    required: "statename must be required",
                  })}
                  style={style.formInput}
                >
                  {states.map((el) => (
                    <option>{el}</option>
                  ))}
                </Select>
                {errors.statename && (
                  <span style={style.errorstyle}>State name is Required</span>
                )}
                <Text style={style.formText}>Goverment Schme Description</Text>
                <Textarea
                  id="content"
                  {...register("content", {
                    required: "content must be required",
                  })}
                  style={style.formInput}
                />
                {errors.content && (
                  <span style={style.errorstyle}>content must be Required</span>
                )}
                <Text style={style.formText}>Goverment Schme Images</Text>
                <Input
                  id="image"
                  {...register("image")}
                  type="file"
                  style={style.formInput}
                />
                {errors.image && (
                  <span style={style.errorstyle}>Image is Required</span>
                )}

                <Box style={style.btnbox}>
                  <Button type="submit" style={style.formButton}>
                    Submit
                  </Button>
                </Box>
              </form>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default GovermentschmeEdit;
