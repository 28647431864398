import {
  Box,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Tab,
  Table,
  TableCaption,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import Icon from "../../component/constant/Icon";
import Pagination from "../../component/Pagination";
import { SearchIcon, ViewIcon } from "@chakra-ui/icons";
import OrderDetailModal from "../AdminModal/OrderDetailModal";

const AdminOrder = () => {
  const data = [
    {
      orderid: "#514326",
      customerid: "14514525",
      orderdetails: "Product Name",
      Price: "₹‎78456",
      Date: "20-01-2023",
      Status: "Complete",
    },
    {
      orderid: "#214578",
      customerid: "14511247",
      orderdetails: "Product Name",
      Price: "₹‎45789",
      Date: "17-06-2023",
      Status: "Pending",
    },
    {
      orderid: "#214578",
      customerid: "54578451",
      orderdetails: "Product Name",
      Price: "₹‎547894",
      Date: "17-06-2023",
      Status: "Pending",
    },
    {
      orderid: "#214578",
      customerid: "Akash Patil",
      orderdetails: "Product Name",
      Price: "1547 ",
      Date: "17-06-2023",
      Status: "Complete",
    },
    {
      orderid: "#214578",
      customerid: "Akash Patil",
      orderdetails: "Product Name",
      Price: "1547 ",
      Date: "17-06-2023",
      Status: "Pending",
    },
    {
      orderid: "#214578",
      customerid: "Akash Patil",
      orderdetails: "Product Name",
      Price: "1547 ",
      Date: "17-06-2023",
      Status: "Pending",
    },
    {
      orderid: "#214578",
      customerid: "Akash Patil",
      orderdetails: "Product Name",
      Price: "1547 ",
      Date: "17-06-2023",
      Status: "Cancelled",
    },
    {
      orderid: "#214578",
      customerid: "Akash Patil",
      orderdetails: "Product Name",
      Price: "1547 ",
      Date: "17-06-2023",
      Status: "Pending",
    },
    {
      orderid: "#214578",
      customerid: "Akash Patil",
      orderdetails: "Product Name",
      Price: "1547 ",
      Date: "17-06-2023",
      Status: "Pending",
    },
    {
      orderid: "#214578",
      customerid: "Akash Patil",
      orderdetails: "Product Name",
      Price: "1547 ",
      Date: "17-06-2023",
      Status: "Pending",
    },
    {
      orderid: "#214578",
      customerid: "Akash Patil",
      orderdetails: "Product Name",
      Price: "1547 ",
      Date: "17-06-2023",
      Status: "Pending",
    },
    {
      orderid: "#214578",
      customerid: "Akash Patil",
      orderdetails: "Product Name",
      Price: "1547 ",
      Date: "17-06-2023",
      Status: "Replace",
    },
    {
      orderid: "#214578",
      customerid: "Akash Patil",
      orderdetails: "Product Name",
      Price: "1547 ",
      Date: "17-06-2023",
      Status: "Pending",
    },
    {
      orderid: "#214578",
      customerid: "Akash Patil",
      orderdetails: "Product Name",
      Price: "1547 ",
      Date: "17-06-2023",
      Status: "Pending",
    },
    {
      orderid: "#214578",
      customerid: "Akash Patil",
      orderdetails: "Product Name",
      Price: "1547 ",
      Date: "17-06-2023",
      Status: "Pending",
    },
  ];

  const style = {
    formheading: {
      color: "#404040",
      fontFamily: "Inter",
      fontSize: "40px",
      fontWeight: "700",
      letterSpacing: "1.2px",
      marginTop: "30px",
    },
  };
  return (
    <Box ml={"50px"}>
      <Text style={style.formheading}>Order details</Text>
      <Flex mt={10} justifyContent={"space-between"}>
        <Tabs variant={"unstyled"}>
          <TabList
            bg={"#F5F5F5"}
            color={"#8E8E8E"}
            fontSize={"16px"}
            fontFamily={"Inter"}
            w={"400px"}
            rounded={"md"}
          >
            <Tab _selected={{ color: "#F3FFE1", bg: "#52771A", rounded: "md" }}>
              All Orders
            </Tab>
            <Tab _selected={{ color: "#F3FFE1", bg: "#52771A", rounded: "md" }}>
              Completed
            </Tab>
            <Tab _selected={{ color: "#F3FFE1", bg: "#52771A", rounded: "md" }}>
              Pending
            </Tab>
            <Tab _selected={{ color: "#F3FFE1", bg: "#52771A", rounded: "md" }}>
              Cancel
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <p></p>
            </TabPanel>
            <TabPanel>
              <p></p>
            </TabPanel>
            <TabPanel>
              <p></p>
            </TabPanel>
            <TabPanel>
              <p></p>
            </TabPanel>
          </TabPanels>
        </Tabs>
      
          <Flex mr={10} justifyContent={"space-around"} h={"43px"} gap={3}  color= '#8E8E8E'
            fontFamily={"Inter"}
            w={"400px"}
            rounded={"md"}
          >
              <InputGroup   bg={"#F5F5F5"}>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon />
                </InputLeftElement>
                <Input type="text"  focusBorderColor='#52771A' placeholder="Search Order ID" h={"auto"}  _placeholder={{ opacity: 1, color: '#8E8E8E' }} color={"#52771A"}/>
              </InputGroup>
              <Input   bg={"#F5F5F5"}  focusBorderColor='#52771A' placeholder='Select Date and Time' size='md' type='datetime-local' _placeholder={{ opacity: 1, color: '#8E8E8E' }} color={"#52771A"}/>
          </Flex>
      </Flex>

      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>
                <Flex>
                  Order ID{" "}
                  <Icon type={"UPDOWNARROW"} color="#404040" size={"16px"} />
                </Flex>
              </Th>
              <Th>
                <Flex>
                  Customer ID{" "}
                  <Icon type={"UPDOWNARROW"} color="#404040" size={"16px"} />
                </Flex>
              </Th>
              <Th>
                <Flex>
                  Order Details{" "}
                  <Icon type={"UPDOWNARROW"} color="#404040" size={"16px"} />
                </Flex>
              </Th>
              <Th>
                <Flex>
                  Price
                  <Icon type={"UPDOWNARROW"} color="#404040" size={"16px"} />
                </Flex>
              </Th>
              <Th>
                <Flex>
                  Date
                  <Icon type={"UPDOWNARROW"} color="#404040" size={"16px"} />
                </Flex>
              </Th>
              <Th>
                <Flex>
                  Status
                  <Icon type={"UPDOWNARROW"} color="#404040" size={"16px"} />
                </Flex>
              </Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.map((el) => (
              <Tr>
                <Td>{el.orderid}</Td>
                <Td>{el.customerid}</Td>
                <Td>{el.orderdetails}</Td>
                <Td>{el.Price}</Td>
                <Td>{el.Date}</Td>
                <Td>
                  <Tag
                    variant="subtle"
                    colorScheme={
                      el.Status === "Complete"
                        ? "green"
                        : el.Status === "Pending"
                        ? "orange"
                        : el.Status === "Replace"
                        ? "blue"
                        : "red"
                    }
                  >
                    {el.Status}
                  </Tag>
                </Td>
                <Td>
                 <OrderDetailModal/>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination />
    </Box>
  );
};

export default AdminOrder;
