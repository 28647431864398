import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {loginAdmin,updateUser,authMiddleware,verifyOtpEmail,getAllUsers} from "./UserAPI";

const initialState = {
  value: 0,
  status: "idle",
  userdata: null,
  checkNo: null,
  loginUserData: null,
  adminlogin: null,
  allCustomers:null
};

export const updateUserAsync = createAsyncThunk(
  "user/updateUser",
  async ({UserData,userId}) => {
    const response = await updateUser({UserData,userId});
    return response.data;
  }
);

export const loginAdminAsync = createAsyncThunk(
  "user/loginAdmin",
  async (data) => {
    const response = await loginAdmin(data);
    return response.data;
  }
);
export const authMiddlewareAsync = createAsyncThunk(
  "user/authMiddleware",
  async () => {
    const response = await authMiddleware();
    return response.data;
  }
);
export const verifyOtpEmailAsync = createAsyncThunk(
  "user/verifyOtpEmail",
  async ({email,otp}) => {
    const response = await verifyOtpEmail({email,otp});
    return response.data;
  }
);

export const getAllUsersAsync = createAsyncThunk(
  "user/getAllUsers",
  async (queryString) => {
    const response = await getAllUsers(queryString);
    return response.data;
  }
);

export const UserSlice = createSlice({
  name: "user",
  initialState,

  reducers: {
    increment: (state) => {
      state.value += 1;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(loginAdminAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginAdminAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.adminlogin = action.payload;
      })
      .addCase(verifyOtpEmailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(verifyOtpEmailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.adminlogin = action.payload;
      })
      .addCase(authMiddlewareAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(authMiddlewareAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.adminlogin = action.payload;
      })
      .addCase(updateUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUserAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.userdata = action.payload;
      })
      .addCase(getAllUsersAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllUsersAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.allCustomers = action.payload;
      })
     
  },
});

export const userSelector = (state) => state.user.userdata;
export const loginAdminSelector = (state) => state.user.adminlogin;
export const allUsers = (state)=>state.user.allCustomers;

export default UserSlice.reducer;
