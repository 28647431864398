import { Box } from "@chakra-ui/react";
import { FaHome, FaRegThumbsDown, FaRegThumbsUp, FaTag } from "react-icons/fa";
import { GoVerified } from "react-icons/go";
import { MdContactPage, MdOutlineMiscellaneousServices, MdOutlineRoundaboutRight } from "react-icons/md";
import { PiCaretUpDownFill } from "react-icons/pi";
import { FaLocationDot } from "react-icons/fa6";
import { IoIosPeople } from "react-icons/io";
  const Icon = ({ type, color = "#52771A", size = 20 }) => {
    const getIcon = () => {
      const iconTypeMap = {
        PRODUCT:<FaTag color={color} size={size}/>,
       SERVICE:<MdOutlineMiscellaneousServices  color={color} size={size} />,
       ABOUT:<MdOutlineRoundaboutRight color={color} size={size}/>,
       SCHEME:<GoVerified color={color} size={size}/>,
       CONTACT:<MdContactPage color={color} size={size}/>,
       HOME:<FaHome color={color} size={size}/>,
       UPDOWNARROW: <PiCaretUpDownFill  color={color} size={size}/>,
       LOCATION: <FaLocationDot  color={color} size={size}/>,
       CUSTOMER: <IoIosPeople   color={color} size={size}/>,
       thumbsUp: <FaRegThumbsUp   color={color} size={size}/>,
       thumbsDown: <FaRegThumbsDown   color={color} size={size}/>,

      };
      return iconTypeMap?.[type];
    };
  
    return <Box>{getIcon()}</Box>;
  };
  
  export default Icon;
  