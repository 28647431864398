import React from "react";
import { Route, Routes } from "react-router-dom";
import AddProductForm from "./../Admin/Forms/AddProductForm";
import Products from "../Admin/Products/Products";
import AddCategory from "./../Admin/Forms/AddCategory";
import CategoryList from "./../Admin/Products/CategoryList";
import AddSubCategory from "../Admin/Forms/AddSubCategory";
import SubCategoryList from "../Admin/Products/SubCategoryList";
import AddService from "../Admin/Forms/AddService.";
import ServiceList from "../Admin/Products/ServiceList";
import AddGovermentSchme from "../Admin/Forms/AddGovermentSchme";
import GovermentschmeList from "../Admin/Products/GovermentschmeList";
import AdminOrder from "./../Admin/Order/AdminOrder";
import PrivateRoutes from "./PrivateRoute/PrivateRoute";
import AdminDrashboard from "../Admin/AdminDrashboard/AdminDrashboard";
import CustomersPage from "../Admin/CustomersPage";

const RouterPage = () => {
  return (
    <Routes>
      <Route path="/addproduct" element={<PrivateRoutes><AddProductForm /></PrivateRoutes>}></Route>
      <Route path="/products" element={<PrivateRoutes><Products /></PrivateRoutes>}></Route>
      <Route path="/addcategory" element={<PrivateRoutes><AddCategory /></PrivateRoutes>}></Route>
      <Route path="/categorylist" element={<PrivateRoutes><CategoryList /></PrivateRoutes>}></Route>
      <Route path="/addsubcategory" element={<PrivateRoutes><AddSubCategory /></PrivateRoutes>}></Route>
      <Route path="/addservice" element={<PrivateRoutes><AddService /></PrivateRoutes>}></Route>
      <Route path="/subcategorylist" element={<PrivateRoutes><SubCategoryList /></PrivateRoutes>}></Route>
      <Route path="/servicelist" element={<PrivateRoutes><ServiceList /></PrivateRoutes>}></Route>
      <Route path="/addgovermentschme" element={<PrivateRoutes><AddGovermentSchme /></PrivateRoutes>}></Route>
      <Route
        path="/govermentschmelist"
        element={<PrivateRoutes><GovermentschmeList /></PrivateRoutes>}
      ></Route>
      <Route path="/OrderPageAdmin" element={<PrivateRoutes><AdminOrder /></PrivateRoutes>}></Route>
      <Route path="/customers" element={<PrivateRoutes><CustomersPage /></PrivateRoutes>}></Route>
      <Route path="/dashboard" element={<AdminDrashboard/>}></Route>
    </Routes>
  );
};

export default RouterPage;
