import React, { useEffect, useRef } from "react";
import {Button,Modal,ModalOverlay,ModalContent,ModalCloseButton,ModalBody,Box,Text,Input,Select,Textarea,useDisclosure,ModalHeader,IconButton} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { updateCategoryAsync } from "../../features/Category/CategorySlice";
import Swal from "sweetalert2";
import axios from "axios";
import { AddIcon, EditIcon } from "@chakra-ui/icons";
import { editIcon } from "../../component/constant/Icons";

const style = {
  form_mainbox: {
    width: "600px",
    borderRadius: "28px",
    background: "#f3ffe1",
    padding: "24px",
    margin: "auto",
    marginTop: "20px",
  },
  formheading: {
    color: "#404040",
    fontFamily: "Inter",
    fontSize: "40px",
    fontWeight: "700",
    letterSpacing: "1.2px",
    marginTop: "30px",
    marginLeft: "50px",
  },
  formInput: {
    width: "100%",
    height: "50px",
    borderRadius: "14px",
    border: "1px solid #A0A0A0",
    backgroundColor: "#FFF",
    paddingLeft: "10px",
    marginBottom: "12px",
  },
  formText: {
    color: "#404040",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "500",
    marginBottom: "6px",
  },
  formButton: {
    borderRadius: "14px",
    background: "#52771A",
    color: "#f3ffe1",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "500",
    padding: "12px 16px",
  },
  btnbox: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  errorstyle: {
    color: "red",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "500",
    marginTop: "4px",
  },
};

function EditCustomerDetail({ id, name, email, mobile }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues:{
      name,
      email,
      mobile
    }
  });

  const onSubmit = (data) => {
    console.log(data)
    // cloudFormSubmit(data);
  }

  // const cloudFormSubmit = async (data) => {
  //   let imageUrls = null;
  //   if (data.categoryImage[0] !== undefined) {
  //     const formData = new FormData();
  //     formData.append("file", data.categoryImage[0]);
  //     formData.append("upload_preset", "mun8qyj6");
  //     formData.append("folder", "category");

  //     try {
  //       const response = await axios.post("https://api.cloudinary.com/v1_1/dbliylxne/image/upload", formData);
  //       imageUrls = response.data.secure_url;
  //       console.log("Upload to Cloudinary:", response.data.secure_url);
  //     } catch (error) {
  //       console.error("Image upload failed:", error);

  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: "Image upload failed.",
  //       });
  //       return;
  //     }
  //   }

  //   if (imageUrls) {
  //     data.categoryImage = imageUrls;
  //     dispatch(updateCategoryAsync({ id, data }));
  //   } else {
  //     data.categoryImage = image;
  //     dispatch(updateCategoryAsync({ id, data }));
  //   }
  // };
  // useEffect(() => {
  //   setValue("categoryName", name);
  //   setValue("categoryDescription", categoryDescription);
  // }, [name, categoryDescription]);

  return (
    <>
      <IconButton id="click-button" onClick={onOpen} ref={containerRef}
        variant="solid"
        colorScheme="red"
        aria-label="Done"
        size={"sm"}
        icon={<EditIcon  boxSize={4}/>}
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay
          style={{
            backdropFilter: "blur(2px)", // Apply the backdrop blur effect
          }}
        />
        <ModalContent style={style.form_mainbox}>
          <ModalHeader
            style={{
              color: "#404040",
              fontFamily: "Inter",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "normal",
            }}
          >
            Edit Customer
          </ModalHeader>
          <ModalCloseButton backgroundColor="#8E8E8E" borderRadius="50%" color="white" />
          <ModalBody>
            <Box>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Text style={style.formText}>Full Name</Text>
                <Input type="text" {...register("name",{required:true})} style={style.formInput} placeholder="Enter full name" />
                
                {errors.name && <span style={style.errorstyle}>Name is Required</span>}

                <Text style={style.formText}>Email</Text>
                <Input type="email" {...register("email",{required:true})} style={style.formInput} placeholder="Enter email" />
                
                {errors.email && (
                  <span style={style.errorstyle}>Email is Required</span>
                )}
                <Text style={style.formText}>Mobile</Text>
                <Input type="number" {...register("mobile",{required:true})} style={style.formInput} placeholder="Enter Mobile no." />
              
                {errors.mobile && (
                  <span style={style.errorstyle}>Mobile no. is Required</span>
                )}
                <Box style={style.btnbox}>
                  <Button type="submit" leftIcon={<AddIcon />} style={style.formButton}>
                    Update User
                  </Button>
                </Box>
              </form>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default EditCustomerDetail;
