import {

  Box,
  Button,
  Flex,
  IconButton,
  Input,
  Select,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AddIcon } from "@chakra-ui/icons";
import ProductSpecification from "../AdminComponent/ProductSpecification";
import Swal from "sweetalert2";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { selectAdmincategoryData } from "../../features/Category/CategorySlice";
import { fetchSubCategoryNameByCategoryIdAsync, selectSubcategoryByCategoryName } from "../../features/Subcategory/SubcategorySlice";
import { createProductAsync } from "../../features/Product/ProductSlice";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import CenteredSpinner from "../../component/constant/CenteredSpinner ";

const style = {
  form_mainbox: {
    width: "1024px",
    borderRadius: "28px",
    background: "#f3ffe1",
    padding: "24px",
    margin: "auto",
    marginTop: "20px",
  },
  formheading: {
    color: "#404040",
    fontFamily: "Inter",
    fontSize: "40px",
    fontWeight: "700",
    letterSpacing: "1.2px",
    marginTop: "30px",
    marginLeft: "50px",
  },
  formInput: {
    width: "100%",
    height: "50px",
    borderRadius: "14px",
    border: "1px solid #A0A0A0",
    backgroundColor: "#FFF",
    paddingLeft: "10px",
    marginBottom: "12px",
  },
  formText: {
    color: "#404040",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "500",
    marginBottom: "6px",
  },
  formButton: {
    borderRadius: "14px",
    background: "#52771A",
    color: "#f3ffe1",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "500",
    padding: "12px 16px",
  },
  btnbox: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "30px",
    gap: "20px",
  },
  cancelButton: {
    borderRadius: "8px",
    background: "#F5F5F5",
    color: "#000",
  },
  errorstyle: {
    color: "red",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "500",
    marginTop: "4px",
  },
};

const AddProductForm = () => {
  const CategorySelector = useSelector(selectAdmincategoryData);
  // const SubCategorySelector = useSelector(selectSubcategoryByCategoryName);
  const dispatch = useDispatch();
   const[isloding, setLoding] =  useState(false);
   const navigate = useNavigate(); // This replaces useRouter
  const [categoryId, setCategoryId] = useState(null);
   const [specifications, setSpecifications] = useState([
     { name: "", description: "" },
   ]);

    const handleSpecificationChange = (newSpecifications) => {
      setSpecifications(newSpecifications);
    };

  const {
    register,
    handleSubmit,
   setValue,
    formState: { errors },
  } = useForm();

//    useEffect(() => {
    
//     if(categoryId){
// dispatch(fetchSubCategoryNameByCategoryIdAsync(categoryId));
//     }
     
//    }, [categoryId]);


      const onSubmit = (data) => handleFormSubmit(data);
      
  const handleFormSubmit = async (data) => {
    data.categoryId = categoryId;
    setLoding(true);
    const imageUrls = [];
    for (let i = 0; i < data.productImage.length; i++) {
      const formData = new FormData();
      formData.append("file", data.productImage[i]);
      formData.append("upload_preset", "mun8qyj6");
      formData.append("folder", "products");

      try {
        const response = await axios.post(
          "https://api.cloudinary.com/v1_1/dbliylxne/image/upload",
          formData
        );
        imageUrls.push(response.data.secure_url);
        console.log("Upload to Cloudinary:", response.data.secure_url);
      } catch (error) {
        console.error("Image upload failed:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Image upload failed.",
        });
        return;
      }
    }
    if (imageUrls.length > 0 ) {
      data.productImage = imageUrls;
      data.productSpicility = specifications;
      dispatch(createProductAsync(data)).then((res) =>{
        if(res?.payload?.status === 1){
          setLoding(false);
          navigate('/products');
    toast.success("Product Added Sucessfully");
    setValue("productName", "");
    setValue("productRate", "");
    setValue("pricePerUnit", "");
    setValue("productDescription", "");
    setValue("productImage", "");
    setValue("pricePerUnit", "");
    setValue("productSpicility", "");
    setSpecifications([{ name: "", description: "" }]);
        }
      });


    }
  };

      


  return (
    <>
      {isloding ? <CenteredSpinner/> : (<Box
      height="1024px"
      width="100%"
      overflowY="scroll"
      border="1px solid #ccc"
      p="4"
    >
      <Toaster toastOptions={{ duration: 3000 }} />

      <Box>
        <Text style={style.formheading}>Add Products</Text>
      </Box>

      <Box style={style.form_mainbox}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Text style={style.formText}>Product Name</Text>
          <Input
            id="productName"
            {...register("productName", {
              required: "product name must be required",
            })}
            style={style.formInput}
          />
          {errors.productName && (
            <span style={style.errorstyle}>{errors.productName.message}</span>
          )}
          <Box
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2,414px)",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Text style={style.formText}>Product Price</Text>
              <Input
                id="productRate"
                {...register("productRate", {
                  required: "product price must be required",
                })}
                style={style.formInput}
              />
              {errors.productRate && (
                <span style={style.errorstyle}>
                  {errors.productRate.message}
                </span>
              )}
            </Box>
            <Box>
              <Text style={style.formText}>Product Price / Unit</Text>
              <Input
                id="pricePerUnit"
                {...register("pricePerUnit")}
                style={style.formInput}
              />
            </Box>
          </Box>
          <Box
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2,414px)",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Text style={style.formText}>Product Category</Text>
              <Select
                id="categoryName"
                {...register("categoryName", {
                  required: "category name must be required",
                })}
                style={style.formInput}
                placeholder="Select Category"
                onChange={(e) => {
                  const selectedCategory = CategorySelector?.find(
                    (el) => el.categoryName === e.target.value
                  );
                  if (selectedCategory) {
                    setCategoryId(selectedCategory._id);
                  }
                }}
              >
                {CategorySelector?.length > 0 &&
                  CategorySelector.map((el) => (
                    <option value={el.categoryName}>{el.categoryName}</option>
                  ))}
              </Select>
              {errors.categoryName && (
                <span style={style.errorstyle}>
                  {errors.categoryName.message}
                </span>
              )}
            </Box>
            {/* <Box>
              <Text style={style.formText}>Product Sub Category</Text>
              <Select
                id="subcategoryId"
                {...register("subcategoryId", {
                  required: "subcategory name must be required",
                })}
                style={style.formInput}
                placeholder="Select Subcategory"
              >
                {SubCategorySelector?.status == 1 &&
                  SubCategorySelector?.data.map((el) => (
                    <option value={el._id}>{el.subcategoryName}</option>
                  ))}
              </Select>
              {errors.subcategoryId && (
                <span style={style.errorstyle}>
                  {errors.subcategoryId.message}
                </span>
              )}
            </Box> */}
          </Box>

          <Text style={style.formText}>Product Description</Text>
          <Textarea
            id="productDescription"
            {...register("productDescription", {
              required: "product description must be required",
            })}
            style={style.formInput}
          />
          {errors.productDescription && (
            <span style={style.errorstyle}>
              {errors.productDescription.message}
            </span>
          )}
          <Text style={style.formText}>Product Image</Text>
          <Input
            multiple
            type="file"
            id="productImage"
            style={style.formInput}
            {...register("productImage", {
              required: "product image must be required",
            })}
          />
          {errors.productImage && (
            <span style={style.errorstyle}>{errors.productImage.message}</span>
          )}
          <Text style={style.formText}>Specifiation Details</Text>
          <Box style={{ backgroundColor: "#F5F5F5" }}>
            <ProductSpecification
              id="productSpicility"
              specifications={specifications}
              onSpecificationChange={handleSpecificationChange}
              {...register("productSpicility")}
            />
          </Box>
          <Box style={style.btnbox}>
            <Button style={style.cancelButton}>cancel</Button>
            <Button style={style.formButton} type="submit">
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Box>)}
    </>
  );
};

export default AddProductForm;
